/* pages.css */

.navbar-expand-lg {
  height: 80px;
  opacity: 0.95;
}

.om1 {
  width: 66.4px;
  height: 47px;
  color: #56ccf2;
  font-family: Work Sans;
  font-weight: 600;
  font-size: 39.06px;
  line-height: 45.82px;
}

.pirit1 {
  width: 66.4px;
  height: 47px;
  font-family: Work Sans;
  font-weight: 600;
  font-size: 39.06px;
  line-height: 45.82px;
}

.nav1 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  color: white;
}

.nav1:hover {
  color: #56ccf2;
}

#navbarSupportedContent {
  .nav-item a {
    color: #fff;
  }

  .nav-item.active a {
    color: #56ccf2;
    transition: 0.3 ease background-color;
  }

  .nav-item.active .nav12 {
    background-color: #56ccf2;
  }
}

.nav12 {
  border-radius: 50px;
  border: 2px solid #56ccf2;
  padding: 8px 20px;
  font-weight: 600px;
  text-align: center;
  width: fit-content;
}

.nav12:hover {
  background-color: #56ccf2;
}

.dropdown-menu {
  background: currentColor;
  margin-left: -100px;
  margin-top: 20px;
}

.nav-item {
  margin-left: 25px;
}

/* MOBILE */
.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}

.icon-mobile-nav {
  color: white;
  height: 30px;
  width: 30px;
}

.icon-mobile-nav[name="close"] {
  display: none;
}

@media (max-width: 991px) {
  .nav12 {
    margin-top: 10px;
  }
}

.navbar-nav123 {
  background: none;
  border: none;
}
