.mt123 {
  margin-top: 10rem !important;
}
.img123 {
  width: 1171px;
  height: 268px;
}

#one,
#two,
#three,
#four,
#five {
  width: 100%;
  border-top: 1px solid #4f4f4f;
  margin-top: 100px;
  margin-bottom: 100px;
}

#six {
  width: 100%;
  border-top: 1px solid #4f4f4f;
  margin-bottom: 100px;
}
