  .hr-lines::before {
    content: " ";
    display: block;
    height: 2px;
    width: 100px;
    position: absolute;
    top: 50%;
    background: rgba(255, 255, 255, 0.2);
    right: 20px;
  }

  .rotate {
    white-space: nowrap;
    width: 0;
    font-size: 50px;
    font-weight: 700;
    line-height: 55px;

    translate: (0px, 110px);
    transform: rotate(-90deg);
    margin-top: 70%;
    color: rgba(255, 255, 255, 0.2);
  }

  .h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 59px;
    font-style: normal;
    width: 278px;
    height: 59px;
    color: #e0e0e0;
  }

  .p1 {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    font-style: normal;
    width: 486px;
    height: 96px;
    color: #828282;
  }

  @media (max-width: 1399px) {
    body {
      .p1 {
        width: 419px;
        font-size: 18px;
      }
    }
  }

  @media (max-width: 1199px) {
    body {
      .services {
        max-width: 1199px;
      }

      .rotate12 {
        margin-top: 10%;
      }

      .h3 {
        font-size: 20px;
      }

      .p1 {
        font-size: 16px;
        width: 350px;
        line-height: 25px;
      }
    }
  }

  @media (max-width: 991px) {
    body {
      .services {
        max-width: 991px;
      }

      .h3 {
        font-size: 16px;
      }
      .p1 {
        width: 260px;
        font-size: 12px;
        line-height: 20px;
      }

      .hr-lines::before {
        width: 50px;
      }
      .rotate {
        font-size: 30px;
      }
    }
  }

  @media (max-width: 767px) {
    body {
      .rotate12 {
        transform: rotate(90deg);
        margin-left: 122px;
      }

      .rotate121 {
        margin-top: -70%;
      }
    }
  }  

  @media (max-width: 575px) {
    body {
      .rotate12 {
        margin-left: 20%;
      }

      .col55 {
        width: 100%;
        text-align: -webkit-center;
      }

      .col77 {
        width: 100%;
        text-align: -webkit-center;
      }
    }
  }


  .services1{
    margin-top: 100px;
  }
