.pp1{
  font-family: sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}

@media (max-width: 767px) {
  body {
    .pp1 {
      text-align: center;
    }
  }
}  

.footer1{
  border-top: 1px solid #4F4F4F;
}

.footer2{
  margin-top: 100px;
}