.hr12221 {
  margin-top: auto;
}

.teams1{
  margin-top: 188px;
}

.card000 {
  background-color: black;
}

.card000:hover{
  transform: scale(1.04);
  transition: 0.3s
}

.card001 {
  margin-top: -25%;
  margin-left: 20px;
  margin-right: 25px;
  background-color: rgb(92, 118, 141);
  opacity: 0.85;
}

.social{
  justify-content: center;
  list-style: none;
  display: flex;
}
.card001 h5::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #fff;
  left: calc(50% - 25px);
  margin-top: 5px;
}

 .social-icon{

  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 27px;
  margin-right: 11px;
  cursor: pointer;
  
}


.social-icon:hover{
  color: #56CCF2;
}

@media (max-width: 1199px) {
  body {
    .teams1{
      margin-top: 188px;
    }
  }
}

@media (max-width: 991px) {
  body {
    .teams1{
      margin-top: 100px;
    }

    .rowa1{
      display: flex;
    }

    .card000 {
      width: 210px;
      height: 210px;
    }

    .card001 {
      margin-right: 10px;
    }

    .card001 h5 {
      font-size: 16px;
      font-weight: 600;
    }

    .card001 span {
      font-size: 12px;
      font-weight: 400;
    }

    .li12 {
      margin-left: -20px;
    }

    .social-icon {
      width: auto;
      height: auto;
    }

  }
}

@media (max-width: 767px) {
  body {
    .teams1 {
      transform: rotate(90deg);
      margin-left: 122px;
      margin-top: 10%;
    }

    .rowa1{
      margin-top: -80%;
    }

    .card000 {
      width: 100%;
      height: auto;
    }

    .card001 {
      margin-right: 20px;
    }
  }
}     

@media (max-width: 575px) {
  body {
    .teams1 {
      margin-left: 22%;
    }

    .team21 {
      margin-bottom: 20px;
    }

    .card001 {
      margin-top: -50px;
    }

    .rowa1{
      margin-top: -80%;
    }
  }
}  

.coreteam{
  margin-top: 100px;
}