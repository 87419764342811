.recent1 {
  margin-top: 26%;
}

.hr-lines1::before {
  content: " ";
  display: block;
  height: 2px;
  width: 100px;
  position: absolute;
  top: 50%;
  background: rgba(255, 255, 255, 0.2);
  right: 20px;
}

.rotate1 {
  white-space: nowrap;
  width: 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 55px;

  translate: (0px, 110px);
  transform: rotate(-90deg);
  margin-top: 70%;
  color: rgba(255, 255, 255, 0.2);
}

.bx {
  border-radius: 16px;
  margin-top: 7%;
  height: 450px;
  background: rgba(80, 58, 231, 0.8);
}

.row1 {
  margin-top: 15%;
}

.h22 {
  width: 180.31px;
  height: 52.86px;
  font-family: Fiira Sans;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

.p2 {
  width: 400px;
  height: 58px;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 29.4px;
  color: rgba(245, 245, 255, 1);
}

.span1 {
  width: 49px;
  height: 24px;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #f2f2f2;
}

.image1 {
  margin-left: 25%;
}

.iphone {
  margin-left: -68px;
  margin-top: 50px;
}

@media (max-width: 1199px) {
  body {
    .recent1{
      margin-top: 30%;
    }
  }
}  

@media (max-width: 991px) {
  
  body {
    .hr-lines1::before {
      width: 50px;
    }

    .rotate1 {
      font-size: 30px;
    }

    .bx {
      height: 350px;
    }
    
    .h22 {
      font-size: 30px;
    }

    .p2 {
      width: 290px;
      font-size: 16px;
    }
    
    .iphone1 {
      height: 250px;
      margin-top: 15%;
    }
  
    .iphone {
      margin-left: 40px;
      margin-top: -225px;
      height: 250px;
    }
  }
}  

@media (max-width: 767px) {
  body {
    .recent1 {
      transform: rotate(90deg);
      margin-left: 122px;
      margin-top: 20%;
    }

    .recent2 {
      margin-top: -80%;
    }

    .p2 {
      width: 229px;
      font-size: 14px;
      line-height: 20px;
    }

    .span1 {
      font-size: 14px;
    }

    .image1 {
      margin-left: 0px;
    }
  }
}  

@media (max-width: 575px) {
  body {
    .recent1 {
      margin-left: 12%;
    }

    .bx {
      height: 100%;
    }

    .row1 {
      width: 100%;
      text-align: -webkit-center;
    }

    .h22 {
      font-size: 40px;
    }

    .p2 {
      font-size: 20px;
      width: 100%;
    }

    .row2 {
      width: 100%;
      text-align: -webkit-center;
    }

    .iphone {
      margin-left: -130px;
      margin-top: 108px;
    }
  }
}

@media (min-width: 991px) {

  .iphone1 {
    height: 344.72px;
    width: 162.54px;
  }

  .iphone {
    height: 344.72px;
    width: 162.54px;
  }
}